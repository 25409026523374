<template>
    <div class="cardTrackReport">
        <div class="member-info">
            <div class="title">会员信息</div>
            <div class="card-box">
                <div class="lable-txt">卡号：</div>
                <div class="from-input">
                    <div class="card-no">
                        <input ref="cardInput" keyBoard type="text" placeholder="请输入卡号或读卡" v-model="cardNo" @keyup.enter="selectReadCard()" maxlength="20"/>
                        <i class="iconfont icon-sousuo" @click="selectReadCard()"></i>
                    </div> 
                    <div class="read-card" @click="readCard()">读卡</div>    
                </div>
                <div class="lable-box">卡号：{{memberInfo?.Card_No}}</div>
                <div class="lable-box">剩余可开票金额：{{memberInfo?.InvoiceMoney_Remain}}</div>
            </div>
        </div>
        <div class="nav-box" >
            <div class="nav-li" :class="{selected:tagType==1}" @click="tagClick(1)">交易明细</div>
            <div class="nav-li" :class="{selected:tagType==2}" @click="tagClick(2)">开票信息</div>
        </div>
        <div class="tab-pane" v-show="tagType==1">
            <div class="filter-box">
                <div class="filter-bnts ">
                    <div class="inline-block" >
                        <div class="flex-box">
                            <div class="lable-txt">交易日期：</div>
                            <div class="from-input">
                                <el-date-picker class="from-date"
                                    v-model="businessHours"
                                    type="daterange"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    :default-time="defaultTime"
                                > </el-date-picker>
                            </div>
                        </div>
                    </div>
                    <div class="inline-block" >
                        <div class="flex-box">
                            <div class="lable-txt">交易类型:</div>
                            <div class="from-input" style="width: 180px;">
                                <el-select v-model="cardTypes"  multiple  collapse-tags @removeTag="()=>cardTypes=[]">
                                    <el-option :value="item.value" :label="item.lable" v-for="item in cardTypeList" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div class="inline-block" >
                        <div class="search-flex">
                            <div class="bnt-search" @click="GetMemberTrackDetail()" >查询</div>
                        </div>
                    </div>
                </div>
                <div class="right-bnts">
                    <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
                </div>
            </div>
            <div class="content-box">
                <div class="table-box headerCol2 isFooter">
                    <el-table class="el-table--scrollable-y" ref="tableEl" border
                        show-summary 
                        :summary-method="tableSummary" 
                        :data="tableListPage"  
                        v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" >
                        <el-table-column fixed type="index" label="序号" width="42">
                            <template #default="scope">
                                {{scope.$index+pagesize*(currentPage-1)+1}}
                            </template>
                        </el-table-column>
                        <el-table-column fixed prop="Card_No" label="卡号" min-width="80" align="left"></el-table-column>
                        <el-table-column fixed prop="Cstm_Name" label="客户姓名" min-width="80" align="left"></el-table-column> 
                        <el-table-column fixed prop="Cstm_Mobile" label="手机号" min-width="90" align="left"></el-table-column> 
                        <el-table-column fixed prop="Card_KindName" label="卡类型" min-width="60"></el-table-column>
                        <el-table-column fixed prop="Biz_ID" label="交易流水号" min-width="120" align="left"></el-table-column>
                        <el-table-column fixed prop="OperationName" label="交易类型" min-width="80"></el-table-column> 
                        <el-table-column label="储值-充值" >
                            <el-table-column prop="PyaName" label="付款方式" min-width="80"></el-table-column> 
                            <el-table-column prop="CZMoney" label="账面金额" min-width="70" align="right" data-format="number"></el-table-column> 
                            <el-table-column prop="CZZSMoney" label="赠送金额" min-width="70" align="right" data-format="number"></el-table-column> 
                            <el-table-column prop="CZFactMoney" label="实收金额" min-width="70" align="right" data-format="number"></el-table-column> 
                        </el-table-column>
                        <el-table-column label="储值-消费" >
                            <el-table-column prop="XFMoney" label="账面金额" min-width="70" align="right" data-format="number"></el-table-column> 
                            <el-table-column prop="XFZSMoney" label="赠送金额" min-width="70" align="right" data-format="number"></el-table-column> 
                            <el-table-column prop="XFFactMoney" label="实收金额" min-width="70" align="right" data-format="number"></el-table-column> 
                        </el-table-column>
                        <el-table-column label="储值-其他交易类型" >
                            <el-table-column prop="OtherMoney" label="账面金额" min-width="70" align="right" data-format="number"></el-table-column> 
                            <el-table-column prop="OtherZSMoney" label="赠送金额" min-width="70" align="right" data-format="number"></el-table-column> 
                            <el-table-column prop="OtherFactMoney" label="实收金额" min-width="70" align="right" data-format="number"></el-table-column> 
                        </el-table-column>
                        <el-table-column label="交易后余额" >
                            <el-table-column prop="AfterMoney" label="账面金额" min-width="70" align="right" data-format="number"></el-table-column> 
                            <el-table-column prop="AfterZSMoney" label="赠送金额" min-width="70" align="right" data-format="number"></el-table-column> 
                            <el-table-column prop="AfterFactMoney" label="实收金额" min-width="70" align="right" data-format="number"></el-table-column> 
                        </el-table-column>
                        <el-table-column prop="BPoint" label="期初积分" min-width="70" align="right" data-format="number"></el-table-column>
                        <el-table-column prop="NPoint" label="本次积分" min-width="70" align="right" data-format="number"></el-table-column>
                        <el-table-column prop="EPoint" label="积分余额" min-width="70" align="right" data-format="number"></el-table-column>
                        <el-table-column prop="Card_CostMoney" label="工本费" min-width="70" align="right" data-format="number"></el-table-column>
                         <el-table-column label="消费账单" >
                            <el-table-column prop="Eat_CheckIndex" label="结账单" min-width="120" align="left" data-format="number"></el-table-column> 
                            <el-table-column prop="Eat_Money" label="账单金额" min-width="70" align="right" data-format="number"></el-table-column> 
                            <el-table-column prop="Zk_Money" label="折扣额" min-width="70" align="right" data-format="number"></el-table-column> 
                        </el-table-column>
                        <el-table-column prop="Rpt_Date" label="报表日期" min-width="80" align="left"></el-table-column>
                        <el-table-column prop="OperateTime" label="操作时间" min-width="130" align="left"></el-table-column>
                        <el-table-column prop="OperateName" label="操作人" min-width="100" align="left"></el-table-column>
                        <el-table-column prop="OperateDoor_Name" label="发生门店" min-width="100" align="left"></el-table-column>
                        <el-table-column prop="Remark" label="备注" min-width="100" align="left"></el-table-column>
                        <el-table-column prop="Card_WriteNumber" label="操作流水号" min-width="100" align="left"></el-table-column>
                    </el-table>
                </div>
                <table-page-btn class="fy" 
                    :default-index="currentPage" 
                    @current-change="(index)=>currentPage=index"
                    :data="tableFilter"
                    @page-change="(list)=>tableListPage=list" 
                    :pageSizes="[20,30,50,100]"
                    :pageSize="pagesize"
                    @size-change="(size)=>pagesize=size"></table-page-btn>
            </div>
        </div>
        <div class="tab-pane" v-show="tagType==2">
            <div class="filter-box">
                <div class="filter-bnts ">
                    <div class="inline-block" >
                        <div class="flex-box">
                            <div class="lable-txt">开票日期：</div>
                            <div class="from-input">
                                <el-date-picker class="from-date"
                                    v-model="businessHours2"
                                    type="daterange"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    :default-time="defaultTime"
                                > </el-date-picker>
                            </div>
                        </div>
                    </div>
                    <div class="inline-block" >
                        <div class="search-flex">
                            <div class="bnt-search" @click="GetMemberInvoices()" >查询</div>
                        </div>
                    </div>
                </div>
                <div class="right-bnts">
                    <div class="bnt" @click="exportExcel2()"><i class="iconfont icon-daochu"></i>导出报表</div>
                </div>
            </div>
            <div class="content-box">
                <div class="table-box">
                    <el-table class="el-table--scrollable-y" ref="tableEl2" border
                        :data="tableListPage2"  
                        v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" >
                        <el-table-column type="index" label="序号" width="42">
                            <template #default="scope">
                                {{scope.$index+pagesize2*(currentPage2-1)+1}}
                            </template>
                        </el-table-column>
                        <el-table-column class-name="money" prop="InvoiceMoney" label="开票金额" min-width="60" align="right" data-format="number"></el-table-column> 
                    <el-table-column prop="Operator_Name" label="操作人" min-width="50" align="left"></el-table-column>
                    <el-table-column prop="Operate_Time" label="操作时间" width="130" align="left"></el-table-column>
                    <el-table-column prop="Door_Name" label="操作门店" min-width="120" align="left"></el-table-column>
                    <el-table-column class-name="money" prop="RemainInvoiceMoney" label="剩余开票金额" width="90" align="right" data-format="number"></el-table-column>
                    <el-table-column prop="Operate_Type" label="操作类型" width="80" >
                        <template #default="scope">
                            <span>{{({1:'开发票',2:'撤销开票',3:'已撤销'})[scope.row.Operate_Type]}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Recharge_Serial" label="充值流水号" min-width="100" align="left"></el-table-column>
                    <el-table-column prop="Invoice_No" label="发票号" min-width="100" align="left"> </el-table-column>
                    <el-table-column prop="Remark" label="备注" min-width="100" align="left"> </el-table-column>
                    </el-table>
                </div>
                <table-page-btn class="fy" 
                    :default-index="currentPage" 
                    @current-change="(index)=>currentPage2=index"
                    :data="table2Filter"
                    @page-change="(list)=>tableListPage2=list" 
                    :pageSizes="[20,30,50,100]"
                    :pageSize="pagesize"
                    @size-change="(size)=>pagesize=size"></table-page-btn>
            </div>
        </div>
        <!-- 选择卡信息 -->
        <modal-load :isShow="cardMemberShow" >
            <select-card-member-info :isShow="cardMemberShow" @closeModel="cardMemberShow=false" :data="cardMemberList" @confirm="selectCardInfo"></select-card-member-info>
        </modal-load>
     </div>
</template>

<script> 

import {selectCardMemberInfo } from '../model'
import { getDiffDay } from '/src/common'
/**会员卡交易轨迹表 */
export default {
    name:'cardRechargeReport',
    components:{
        selectCardMemberInfo
    },
    data(){
        return {
            /**卡号 */
            cardNo:'',
             /**内卡号 */
            cardSNR:'',
            /**卡id */
            card_AutoID:'',
            /**选择卡信息 */
            cardMemberShow:false,
            /**多个卡信息 */
            cardMemberList:[],
            /**会员信息 */
            memberInfo:null,
            pagesize:20,//每页的数据条数
            currentPage:1,//默认开始页面
            tableListPage:[],//分页数据
            /**表格数据 */
            tableList:[],
            //开票记录信息
            tableList2:[],
            pagesize2:20,//每页的数据条数
            currentPage2:1,//默认开始页面
            tableListPage2:[],//分页数据
            /**默认日期范围 */
            defaultTime:[new Date(),new Date()],
            /**选中日期范围 */
            businessHours:[new Date(),new Date()],
            /**开票日期 选中日期范围 */
            businessHours2:[new Date(),new Date()],
            //卡类型选择
            cardTypes: [],
            tagType:1,
            /**卡类型 数据 */
            cardTypeList:[
                {value:1,lable:"发卡"},
                {value:2,lable:"充值"},
                {value:3,lable:"消费扣卡"},
                {value:4,lable:"应收帐户清账"},
                //{value:5,lable:"修改密码"},
                //{value:6,lable:"退卡"},
                {value:7,lable:"补卡"},
                // {value:8,lable:"挂失"},
                // {value:9,lable:"取消挂失"},
                // {value:10,lable:"停用"},
                // {value:11,lable:"取消停用"},
                //{value:12,lable:"兑换物品"},
                //{value:13,lable:"积分清零"},
                {value:14,lable:"卡升级"},
                {value:15,lable:"换卡升级"},
                {value:16,lable:"旧卡换新卡"},
                {value:17,lable:"积分增减"},
                {value:18,lable:"预付金"},
                //{value:19,lable:"售券支付"},
                {value:20,lable:"卡金额增减"}
            ]
        }
    },
    computed:{
        /**筛选数据 */
        tableFilter(){
            let data=this.tableList;
            if(this.cardTypes?.length>0){
                data=data?.filter(it=>this.cardTypes.indexOf(it.OperationKind)>=0)
            }
            return data;
        },
        /**开票信息 筛选数据 */
        table2Filter(){
            let data=this.tableList2;
            let time = JSON.parse(JSON.stringify(this.businessHours2));
            if(time?.length==2){
                let BRpt_Date=new Date(time[0]).Format("yyyy-MM-dd 00:00:00")//开始日期
                let ERpt_Date=new Date(time[1]).Format("yyyy-MM-dd 23:59:59");//结束日期
                data=data?.filter(it=> it.Operate_Time>=BRpt_Date && it.Operate_Time<=ERpt_Date)
            }
            return data;
        }
    },
    mounted(){
    },
    methods:{
        /**点击 读卡 */
        readCard(){
            this.$webBrowser.redCardM1().then((d)=>{
                if(d){
                    if(d.state==0){
                        this.card_AutoID=d.data.cardID;
                        this.cardNo=d.data.cardNo;
                        this.cardSNR=d.data.snr
                        this.selectReadCard()
                    }else{
                        this.$message.error('卡信息读取失败：'+d.message);
                    }
                }
            })
        },
        /**读取会员信息*/
        selectReadCard(){
            if(!this.cardNo && !this.cardSNR){
                this.$message.warning('请填写退卡卡号')
                return
            }
            const loading = this.$loading({
                text: "读取会员信息中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetMemberInfo",{
                DynamicNo:this.cardNo,
                Card_AutoID:this.card_AutoID,
                Card_SN:this.cardSNR,
                IsGetNotIssuedCard:true,
            }).then((data)=>{
                loading.close();
                this.actualMoney='',this.costMoney='',this.SelectPayType=''
                if(data.ResponseHeader.ResultCode==0){
                    this.cardSNR=''
                    this.card_AutoID=''
                    if(data.ResponseBody.length>1){
                        this.cardMemberList=data.ResponseBody;
                        this.cardMemberShow=true;
                    }else{
                        this.selectCardInfo(data.ResponseBody[0])
                    }
                   
                }else{
                    this.$message.error("会员信息读取失败："+data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('会员信息读取失败：'+e);
                console.log('会员信息读取失败：'+e);
            })
        },
        /**多个卡信息时选择一个 */
        selectCardInfo(data){
            this.cardMemberShow=false;
            this.cardNo=data.Card_No;
            this.$nextTick(()=>{
                this.memberInfo = data;
                if(this.memberInfo.Gender==0){
                    this.memberInfo.Gender='男'
                }else{
                    this.memberInfo.Gender='女'
                }

                this.tableList=[];
                this.currentPage=1;
                this.tableList2=[];
                this.currentPage2=1;
            })
        },
        /**Excel导出 */
        exportExcel(){
            if(this.$refs.tableEl){
                this.$excelCommon.elTableToExcel({
                    elTable:this.$refs.tableEl,
                    titleName:"会员轨迹明细报表",
                    list:this.tableFilter
                })
            }
        },
        exportExcel2(){
            if(this.$refs.tableEl){
                this.$excelCommon.elTableToExcel({
                    elTable:this.$refs.tableEl2,
                    titleName:"会员开票记录报表",
                    list:this.table2Filter
                })
            }
        },
        tagClick(type){
            this.tagType = type;
        },
        /**获取会员轨迹明细 */
        GetMemberTrackDetail(){
            if(!this.memberInfo?.Bestech_Card_AutoID){
                this.$message.warning("请先读卡,获取会员信息！");
                this.$refs.cardInput?.focus();
                return;
            }
            let userInfo=this.$auth.getUserInfo();
            let time = JSON.parse(JSON.stringify(this.businessHours));
            if(getDiffDay(time[0],time[1])>90){
                this.$message.warning("最多只可查询90天的数据!");
                return;
            }

            let param={
                User_ID:userInfo?.User_ID,
                Card_Autoid:this.memberInfo.Bestech_Card_AutoID,//卡id
                BRpt_Date: new Date(time[0]).Format("yyyy-MM-dd"),//范围开始日期
                ERpt_Date:new Date(time[1]).Format("yyyy-MM-dd"),//范围结束日期
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            console.log('获取会员轨迹明细 :',param)
            this.$httpAES.post("Bestech.CloudPos.GetMemberBusinessTrackDetail",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tableList = d.ResponseBody?.MemberBusinessTrackDetails||[];
                    this.currentPage=1;
                }else{
                     this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error(e);
                console.log(e);
            })
        },
        //加载开票记录数据
        GetMemberInvoices(){
            if(!this.memberInfo?.Bestech_Card_AutoID){
                this.$message.warning("请先读卡,获取会员信息！");
                this.$refs.cardInput?.focus();
                return;
            }
            this.userInfo=this.$auth.getUserInfo();
            this.tableData=[]
            let param={
                User_ID:this.userInfo?.User_ID,
                Card_No:this.memberInfo?.Card_No, //卡号
                Card_AutoID:this.memberInfo?.Bestech_Card_AutoID,//卡ID
                Operator_Name:this.userInfo?.Login_Name,//操作员
                Operator_Pos:this.userInfo?.Site_Name, //操作站点
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetMemberRechargeInvoices",param).then((data)=>{
                loading.close();
                console.log(data)
                if(data.ResponseHeader.ResultCode==0){
                    this.tableList2=data.ResponseBody;
                }else{
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('查询失败：'+e);
                console.log('查询失败：'+e);
            })
        },
        /**合计 会员轨迹明细*/
        tableSummary({ columns }){
            let total={
                CZMoney:0,
                CZZSMoney:0,
                CZFactMoney:0,
                XFMoney:0,
                XFZSMoney:0,
                XFFactMoney:0,
                OtherMoney:0,
                OtherZSMoney:0,
                OtherFactMoney:0
            };
            this.tableFilter?.forEach(it=>{
                for(let key in total){
                    total[key]+=it[key];
                }
            })

            return columns.map((column,index)=>{
                if(index==1){
                    return "合计"
                }else if(total[column.property]!=undefined){
                    return Number(total[column.property].toFixed(2)); 
                }
            })
        },
    },
}
</script>

<style lang="scss">
@import './cardTrackReport.scss'
</style>